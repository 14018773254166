import { render, staticRenderFns } from "./CreateServer.vue?vue&type=template&id=2b37b71b&scoped=true"
import script from "./CreateServer.vue?vue&type=script&lang=js"
export * from "./CreateServer.vue?vue&type=script&lang=js"
import style0 from "./CreateServer.vue?vue&type=style&index=0&id=2b37b71b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b37b71b",
  null
  
)

export default component.exports